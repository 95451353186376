var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      {
        staticClass: "loader-wrapper",
        class: {
          "no-bg": !_vm.background,
          "small-loader": _vm.size === "small",
          "full-bg": _vm.size === "full"
        }
      },
      [
        _c("div", { staticClass: "loader-body" }, [
          _c("p", [_vm._v(_vm._s(_vm.text))])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }