import { render, staticRenderFns } from "./n-section-loading.vue?vue&type=template&id=34c1de70&scoped=true&"
import script from "./n-section-loading.vue?vue&type=script&lang=js&"
export * from "./n-section-loading.vue?vue&type=script&lang=js&"
import style0 from "./n-section-loading.vue?vue&type=style&index=0&id=34c1de70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c1de70",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\Vuejs-Project\\subsappadminOrg\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34c1de70')) {
      api.createRecord('34c1de70', component.options)
    } else {
      api.reload('34c1de70', component.options)
    }
    module.hot.accept("./n-section-loading.vue?vue&type=template&id=34c1de70&scoped=true&", function () {
      api.rerender('34c1de70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/n-section-loading/n-section-loading.vue"
export default component.exports